import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 10
export const listName = 'fenaju.registros.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Domínio', name: 'dominio', active: true, sortable: true, ordering: 3},
    {label: 'Leiloeiro', name: 'cliente', active: true, sortable: true, ordering: 5},
    {label: 'Documentação', name: 'documentacao', active: true, sortable: true, ordering: 5},
    {label: 'Pagamento', name: 'pagamento', active: true, sortable: true, ordering: 5},
    {label: 'Solicitação', name: 'createdAt', active: true, sortable: true, ordering: 5},
    {label: 'Ticket', name: 'ticket', active: true, sortable: true, ordering: 5},
    {label: 'Registro', name: 'dataRegistro', active: false, sortable: true, ordering: 5},
    {label: 'Expiração', name: 'dataExpiracao', active: false, sortable: true, ordering: 5},
    {label: 'Status', name: 'status', active: true, sortable: true, ordering: 12},
], listVersion)
